import Vue from 'vue'
import Account from 'vue-material-design-icons/Account'
import AlarmMultiple from 'vue-material-design-icons/AlarmMultiple'
import ArrowLeftRight from 'vue-material-design-icons/ArrowLeftRight'
import Bank from 'vue-material-design-icons/Bank'
import Bell from 'vue-material-design-icons/Bell'
import Bullhorn from 'vue-material-design-icons/Bullhorn'
import Cancel from 'vue-material-design-icons/Cancel'
import Cart from 'vue-material-design-icons/Cart'
import CashUsd from 'vue-material-design-icons/CashUsd'
import ChartBar from 'vue-material-design-icons/ChartBar'
import Check from 'vue-material-design-icons/Check'
import CheckAll from 'vue-material-design-icons/CheckAll'
import CheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import ChefHat from 'vue-material-design-icons/ChefHat'
import ChevronDown from 'vue-material-design-icons/ChevronDown'
import ChevronLeft from 'vue-material-design-icons/ChevronLeft'
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import ChevronUp from 'vue-material-design-icons/ChevronUp'
import Circle from 'vue-material-design-icons/Circle'
import CircleEditOutline from 'vue-material-design-icons/CircleEditOutline'
import Clock from 'vue-material-design-icons/Clock'
import Close from 'vue-material-design-icons/Close'
import CloseCircle from 'vue-material-design-icons/CloseCircle'
import Cog from 'vue-material-design-icons/Cog'
import ContentDuplicate from 'vue-material-design-icons/ContentDuplicate'
import CreditCard from 'vue-material-design-icons/CreditCard'
import CurrencyUsd from 'vue-material-design-icons/CurrencyUsd'
import Delete from 'vue-material-design-icons/Delete'
import Dashboard from 'vue-material-design-icons/DesktopMacDashboard'
import DragVertical from 'vue-material-design-icons/DragVertical'
import Email from 'vue-material-design-icons/Email'
import Eye from 'vue-material-design-icons/Eye'
import EyeOff from 'vue-material-design-icons/EyeOff'
import FilterRemove from 'vue-material-design-icons/FilterRemove'
import Finance from 'vue-material-design-icons/Finance'
import FormatListBulleted from 'vue-material-design-icons/FormatListBulleted'
import Grid from 'vue-material-design-icons/Grid'
import History from 'vue-material-design-icons/History'
import Information from 'vue-material-design-icons/Information'
import Lock from 'vue-material-design-icons/Lock'
import Map from 'vue-material-design-icons/Map'
import Moped from 'vue-material-design-icons/Moped'
import Newspaper from 'vue-material-design-icons/Newspaper'
import OpenInNew from 'vue-material-design-icons/OpenInNew'
import Pause from 'vue-material-design-icons/Pause'
import Pencil from 'vue-material-design-icons/Pencil'
import Play from 'vue-material-design-icons/Play'
import Plus from 'vue-material-design-icons/Plus'
import Printer from 'vue-material-design-icons/Printer'
import Receipt from 'vue-material-design-icons/Receipt'
import Refresh from 'vue-material-design-icons/Refresh'
import Send from 'vue-material-design-icons/Send'
import Silverware from 'vue-material-design-icons/Silverware'
import Sort from 'vue-material-design-icons/Sort'
import Star from 'vue-material-design-icons/Star'
import Ticket from 'vue-material-design-icons/Ticket'

const icons = [
  Dashboard,
  Star,
  Cart,
  Finance,
  Silverware,
  Bullhorn,
  CreditCard,
  Clock,
  Account,
  Newspaper,
  Bell,
  Circle,
  Ticket,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  ArrowLeftRight,
  Map,
  Plus,
  Play,
  Pause,
  Close,
  Delete,
  ContentDuplicate,
  Information,
  DragVertical,
  Sort,
  Email,
  Lock,
  Pencil,
  Printer,
  Moped,
  Cancel,
  AlarmMultiple,
  Check,
  CheckCircleOutline,
  CheckAll,
  ChefHat,
  CircleEditOutline,
  FormatListBulleted,
  Grid,
  Cog,
  FilterRemove,
  CashUsd,
  Receipt,
  CurrencyUsd,
  History,
  CloseCircle,
  ChartBar,
  Bank,
  Eye,
  EyeOff,
  Send,
  OpenInNew,
  Refresh,
]

icons.forEach(icon => {
  Vue.component(icon.name, icon)
})
